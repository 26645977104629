import { request } from "@/utils/request";

// 查询广告列表
export const getAdList = (data) =>
  request({
    // url: "/LbsIndexBar/list",
    url: "/LbsIndex",
    data,
    method: "POST",
  });

// 查询轮播广告图
// export const getCarouselAd = () =>
//   request({
//     url: "/LbsIndex/ad",
//     method: "POST",
//   });

// // 查询广告图
// export const getArticleAds = () =>
//   request({
//     url: "/LbsIndex/sys",
//     method: "POST",
//   });

// 查询省份列表
export const getProvinces = () =>
  request({
    url: "/SysCity/provances",
    method: "POST",
  });

// 查询城市列表
export const getCities = (data) =>
  request({
    url: "/SysCity/cities",
    method: "POST",
    data,
  });

// 微信配置
export const wxConfig = (data) =>
  request({
    url: "/wx/page/config",
    method: "POST",
    data,
  });
