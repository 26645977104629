import { request } from "@/utils/request";

// 获取验证码
export const getVcode = (data) =>
  request({
    url: "/LbsUser/login/vcode",
    data,
    method: "POST",
  });

// 登录
export const login = ({ account, vcode }) => {
  const data = new FormData();
  data.append("account", account);
  data.append("vcode", vcode);
  return request({
    url: "/LbsUser/login",
    data,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

// 修改绑定手机号
export const updateAccount = ({ account, vcode }) => {
  const data = new FormData();
  data.append("account", account);
  data.append("vcode", vcode);
  return request({
    url: "/LbsUser/reset/phone",
    data,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

// 查询用户信息
export const getUserInfo = (data) =>
  request({
    url: "/LbsUser/info",
    data,
    method: "POST",
  });

// 修改用户信息
export const updateUserInfo = (data) =>
  request({
    url: "/LbsUser/update",
    data,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

// 实名认证
export const authAccount = (url, data) =>
  request({
    url,
    data,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

// 查询认证信息
export const getAuthInfo = () =>
  request({
    url: "/LbsUser/authInfo",
    method: "POST",
  });

// 新增关注
export const addFollowUser = (data) =>
  request({
    url: "/LbsUserFollow/add",
    data,
    method: "POST",
  });

// 关注列表
export const getAttentionList = (data) =>
  request({
    url: "/LbsUserFollow/list",
    data,
    method: "POST",
  });

// 新增收藏
export const addCollect = (data) =>
  request({
    url: "/LbsUserCollected/add",
    data,
    method: "POST",
  });

// 查询收藏列表
export const getCollectList = (data) =>
  request({
    url: "/LbsUserCollected/list",
    data,
    method: "POST",
  });

// 赞或踩评论
export const likeOrDisComment = (data) =>
  request({
    url: "/LbsCommentLike/like",
    data,
    method: "POST",
  });

// 注销
export const logout = () =>
  request({
    url: "/LbsUser/logout",
    method: "POST",
  });
