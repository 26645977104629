import { getAdList, getProvinces, getCities, wxConfig } from "@/api/common";
import { getStorage, setStorage } from "@/utils/storage";
import wx from "weixin-js-sdk";

export default {
  state: {
    adList: [],
    provinces: [],
    province: getStorage("province") || 0,
    cities: [],
    city: getStorage("city") || 0,
    wxInfo: {},
  },
  mutations: {
    setAdList(state, payload = []) {
      state.adList = payload;
    },
    setProvinces(state, payload = []) {
      state.provinces = payload;
      // setStorage("provinces", payload);
    },
    setProvince(state, payload = 0) {
      state.province = payload;
      setStorage("province", state.province);
    },
    setCities(state, payload = []) {
      state.cities = payload;
      // setStorage("provinces", payload);
    },
    setCity(state, payload = "0_0") {
      state.city = payload;
      setStorage("city", state.city);
    },
    setWxConfig(state, payload) {
      state.wxInfo = payload || {};
    },
  },
  actions: {
    // 查询全国行政区域
    async getProvinces(a) {
      const { commit, state, dispatch } = a;
      if (state.provinces.length > 0) {
        return;
      }
      try {
        const res = await getProvinces();
        if (res.code === 0) {
          const data = res.data.row || [];
          commit("setProvinces", data);
          if (!state.province) {
            commit("setProvince", 0);
          }
          dispatch("getCities", { province: state.province || 0 });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getCities({ commit, state, dispatch }, { province, resolve }) {
      try {
        const res = await getCities({ province });
        if (res.code === 0) {
          const data = res.data.row || [];
          commit("setCities", data);
          state.city || commit("setCity", `${state.province}_0`);
          dispatch("getAdList");
          resolve &&
            resolve(
              data.map((c, i) => ({
                label: c,
                value: `${state.province}_${i}`,
                leaf: true,
              }))
            );
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getAdList({ commit, state }) {
      try {
        const cityVal = state.city || "";
        const city =
          typeof cityVal === "number" ? cityVal : cityVal.split("_")?.[1];
        const res = await getAdList({
          province: state.province,
          city: +city,
          type: "res",
          // page: 1,
          // limit: 7,
        });
        if (res.code === 0) {
          const data = (res.data.row || []).map((item) => ({
            ...item,
            // fullpath: `https://985.cnhs88.com${item.bar}`,
          }));
          commit("setAdList", data);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async wxConfig({ commit }) {
      try {
        const page = location.href;
        const res = await wxConfig({ page });
        if (res.code === 0) {
          commit("setWxConfig", res.data);
          wx.config(res.data);
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
