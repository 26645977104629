const files = require.context(".", false, /\.js$/);
const modules = {};

files.keys().forEach((key) => {
  if (key === "./index.js") return;
  const defaultModule = files(key).default;
  const name = key.replace("./", "").replace(".js", "");
  if (!defaultModule.name) {
    defaultModule.name = name;
  }
  modules[name] = files(key).default; // 读取出文件中的default模块
});
export default modules;
