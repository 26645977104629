export function setStorage(key, value) {
  const val = JSON.stringify(value) || JSON.stringify("");
  localStorage.setItem(key, val);
}

export function getStorage(key) {
  const value = localStorage.getItem(key);
  return JSON.parse(value);
}

export function removeSorage(key) {
  localStorage.removeItem(key);
}

export function clearStorage() {
  localStorage.clear();
}

export function setSession(key, value) {
  const val = JSON.stringify(value) || JSON.stringify("");
  sessionStorage.setItem(key, val);
}

export function getSession(key) {
  const value = sessionStorage.getItem(key);
  return JSON.parse(value);
}

export function removeSession(key) {
  sessionStorage.removeItem(key);
}

export function clearSession() {
  sessionStorage.clear();
}
