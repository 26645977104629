import axios from "axios";
import store from "@/store";
import { Loading, Message } from "element-ui";
import { getStorage } from "@/utils/storage";

// 超时时间
axios.defaults.timeout = 300000;

// http请求拦截器
// var loadinginstace, noLoading
axios.interceptors.request.use(
  (config) => {
    // element ui Loading方法
    // loadinginstace =
    //   !noLoading &&
    //   Loading.service({
    //     fullscreen: true,
    //     text: '正在加载...',
    //     background: 'rgba(255, 255, 255, 0.3)'
    //   })
    //设置请求头
    // config.headers["Content-Type"] = "application/json;charset=UTF-8;";
    config.headers["Authorization"] = `Bearer ${getStorage("TOKEN")}`;
    return config;
  },
  (error) => {
    // loadinginstace && loadinginstace.close()
    Message.closeAll();
    Message.error({
      message: "加载超时",
    });
    return Promise.reject(error);
  }
);
// http响应拦截器
axios.interceptors.response.use(
  (data) => {
    // 响应成功关闭loading
    // loadinginstace && loadinginstace.close()
    return data;
  },
  (error) => {
    // loadinginstace && loadinginstace.close()
    return Promise.reject(error);
  }
);
const request = (param) => {
  const noLoading = param.noLoading;
  const noMessage = param.noMessage;
  // element ui Loading方法
  const loadinginstace =
    !noLoading &&
    Loading.service({
      fullscreen: true,
      text: "正在加载...",
      background: "rgba(255, 255, 255, 0.3)",
    });
  return new Promise((resolve, reject) => {
    const url =
      process.env.NODE_ENV === "production"
        ? `https://985.cnhs88.com${param.url}`
        : param.url;
    axios({
      url: `${url}`,
      method: param.method || "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8;",
        ...param.headers,
      },
      params: param.params || "",
      responseType: param.responseType,
      data: param.data || "",
    })
      .then(async (res) => {
        !noLoading && loadinginstace && loadinginstace.close();
        if (res.headers["content-type"].includes("octet-stream") && res.data) {
          resolve(res.data);
        } else if (Number(res.data.code) === 0) {
          resolve(res.data);
        } else if (Number(res.data.code) === -2) {
          // store.dispatch("logout");
          store.dispatch("openlogin", true);
          reject(res.data);
        } else {
          // if (+res.data.code === 401 && res.data.status === 2) {
          //   // status == 2 时，接口返回刷新后 token
          //   const body = res.data?.body || {};
          //   body.token && setStorage("TOKEN", body.token);
          //   const response = await axios.request(res.config); // 重新请求接口
          //   return resolve(response);
          // }
          if (res.data?.message === "未登录") {
            store.dispatch("logout");
          }
          reject(res.data);
          Message.closeAll();
          noMessage ||
            Message.error({
              message: res?.data?.msg || res?.data?.message || "网络错误",
            });
        }
      })
      .catch((err) => {
        !noLoading && loadinginstace && loadinginstace.close();
        let message = "";
        reject(err.response);
        switch (+err.response.code) {
          case 401:
            message = "没有操作权限，请联系管理员";
            break;
          case 403:
            message = "账号已被冻结，拒绝访问";
            break;
          case 406:
            message = "无操作权限，请联系管理员为角色赋权";
            break;
          default:
            message = "系统错误，请联系管理员";
            break;
        }
        Message.closeAll();
        noMessage ||
          Message.error({
            message: message,
            duration: 6000,
          });
      });
  });
};

const http = request;
export { axios, request, http };
