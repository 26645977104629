import router from "@/router";
import { logout } from "@/api/user";
import {
  setStorage,
  getStorage,
  // setSession,
  // getSession,
  clearStorage,
} from "@/utils/storage";

export default {
  state: {
    user: getStorage("user") || {}, // 登录用户信息
    openLogin: false,
  },
  mutations: {
    setUser(state, payload = {}) {
      state.user = payload;
      state.user.avatar =
        state.user.avatar || "https://985.cnhs88.com/static/avatar.png";
      setStorage("user", state.user);
    },
    setUserItem(state, payload = {}) {
      state.user = {
        ...state.user,
        ...payload,
      };
      setStorage("user", state.user);
    },
    setOpenLogin(state, payload = false) {
      state.openLogin = payload;
    },
  },
  actions: {
    user({ commit }, payload) {
      setStorage("TOKEN", payload.token);
      setStorage("AUTHTIME", new Date().getTime());
      commit("setUser", payload);
    },
    userItem({ commit }, { key, value }) {
      commit("setUserItem", { [key]: value });
    },
    openlogin({ commit }, payload = false) {
      commit("setUser", {});
      setStorage("TOKEN", "");
      clearStorage();
      commit("setOpenLogin", payload);
    },
    /**
     * 登出
     */
    async logout({ commit }) {
      try {
        const res = await logout();
        if (res.code === 0) {
          commit("setUser", {});
          setStorage("TOKEN", "");
          clearStorage();
          // 清除可能存在的定时器
          const id = setTimeout(() => {}, 0);
          for (let i = id - 2; i < id + 2; i++) {
            i && clearTimeout(i);
          }
          router.push("/comprehensive");
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
