import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};

const routes = [
  {
    path: "/",
    name: "core",
    component: () => import("@/views/core"),
    // redirect: '/dashboard',
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("@/views/home"),
      },
      {
        path: "/comprehensive",
        name: "comprehensive",
        component: () => import("@/views/comprehensive"),
      },
      {
        path: "/negative-comment",
        name: "negative-comment",
        component: () => import("@/views/negative-comment"),
      },
      {
        path: "/good-reputation",
        name: "good-reputation",
        component: () => import("@/views/good-reputation"),
      },
      {
        path: "/active",
        name: "active",
        component: () => import("@/views/active"),
      },
      {
        path: "/industry-information",
        name: "industry-information",
        component: () => import("@/views/industry-information"),
      },
      {
        path: "/person-center",
        name: "person-center",
        component: () => import("@/views/person-center"),
      },
      {
        path: "/author-website",
        name: "author-website",
        component: () => import("@/views/author-website"),
      },
      {
        path: "/company-detail",
        name: "company-detail",
        component: () => import("@/views/company-detail/"),
        // beforeEnter: function (to, from, next) {
        //   const flag =
        //     /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        //       navigator.userAgent
        //     );
        //   if (flag) {
        //     next({ path: "/company-detail/mobile", query: to.query });
        //   } else {
        //     next();
        //   }
        // },
      },
      {
        path: "/company-detail/mobile",
        name: "company-detail/mobile",
        component: () => import("@/views/company-detail/mobile-index"),
      },
      {
        path: "/information-detail",
        name: "information-detail",
        component: () => import("@/views/information-detail"),
        // beforeEnter: function (to, from, next) {
        //   const flag =
        //     /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        //       navigator.userAgent
        //     );
        //   if (flag) {
        //     next({ path: "/information-detail/mobile", query: to.query });
        //   } else {
        //     next();
        //   }
        // },
      },
      {
        path: "/information-detail/mobile",
        name: "information-detail/mobile",
        component: () => import("@/views/information-detail/mobile-index"),
      },
      {
        path: "/publish",
        name: "publish",
        component: () => import("@/views/publish"),
      },
      {
        path: "/404",
        name: "404",
        component: () => import("@/views/error-page/404.vue"),
      },
      {
        path: "*", // 所有未定义路由，全部重定向到404页
        redirect: {
          name: "comprehensive",
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: process.env.NODE_ENV === "production" ? "history" : "hash",
  routes,
});

export default router;
